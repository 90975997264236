@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  a {
    @apply text-theme-main no-underline hover:cursor-pointer hover:underline;
  }

  :root {
    --color-app: linear-gradient(117.12deg, #efedf5 0%, #a094c9 99.17%);
    --color-theme-main: #604ca5;
    --color-theme-strong: #382d62;
    --color-theme-weak: #9c8fcc;
    --color-theme-weak-2: #ddd9ed;
    --color-theme-weak-3: #f0eef7;
    --color-agent-main: #604ca5;
    --color-agent-strong: #382d62;
    --color-agent-weak: #9c8fcc;
    --color-agent-weak-2: #ddd9ed;
    --color-agent-weak-3: #f0eef7;
    --color-admin-main: #1e8c93;
    --color-admin-strong: #125458;
    --color-admin-weak: #78babe;
    --color-admin-weak-2: #d2e8e9;
    --color-admin-weak-3: #e9f4f4;
    --color-client-main: #0f499f;
    --color-client-strong: #092c5f;
    --color-client-weak: #6f92c5;
    --color-client-weak-2: #cfdbec;
    --color-client-weak-3: #f0f5fb;
    --color-main: #d0cfd2;
    --color-strong: #7c7981;
    --color-weak: #e3e2e4;
    --color-weak-2: #f5f4f5;
    --color-weak-3: #fafafa;
    --color-void: white;
    --color-header: #0c0c0d;
    --color-paragraphs: #535157;
    --color-muted: #878787;
    --color-disabled: #cbcacc;
    /* Supplementary colors */
    --color-danger-weak: #fef2f2;
    --color-danger-main: #e21b1b;
    --color-danger-strong: #bf0c0c;
    --color-error-weak: #fef2f2;
    --color-error-main: #e21b1b;
    --color-error-strong: #bf0c0c;
    --color-warning-weak: #fffbeb;
    --color-warning-main: #eda304;
    --color-warning-strong: #a7680a;
    --color-success-weak: #f6ffe2;
    --color-success-main: #4dbc25;
    --color-success-strong: #218712;
    --color-info-weak: #eff6ff;
    --color-info-main: #3b82f6;
    --color-info-strong: #1d4ed8;
    --color-energy-weak: #fff7ed;
    --color-energy-main: #f97316;
    --color-energy-strong: #c2410c;
    --color-fuchsia-weak: #fdf4ff;
    --color-fuchsia-main: #d946ef;
    --color-fuchsia-strong: #a21caf;
    --color-red-weak: #fef2f2;
    --color-red-main: #e21b1b;
    --color-red-strong: #bf0c0c;
    --color-orange-weak: #fff7ed;
    --color-orange-main: #f97316;
    --color-orange-strong: #c2410c;
    --color-amber-weak: #fffbeb;
    --color-amber-main: #eda304;
    --color-amber-strong: #a7680a;
    --color-green-weak: #f6ffe2;
    --color-green-main: #4dbc25;
    --color-green-strong: #218712;
    --color-teal-weak: #f0fdfa;
    --color-teal-main: #14b8a6;
    --color-teal-strong: #115e59;
    --color-sky-weak: #eff6ff;
    --color-sky-main: #3b82f6;
    --color-sky-strong: #1d4ed8;
    --color-violet-weak: #f5f3ff;
    --color-violet-main: #8b5cf6;
    --color-violet-strong: #5b21b6;
  }

  .theme-agent {
    --color-app: linear-gradient(117.12deg, #efedf5 0%, #a094c9 99.17%);
    --color-theme-main: #604ca5;
    --color-theme-strong: #382d62;
    --color-theme-weak: #9c8fcc;
    --color-theme-weak-2: #ddd9ed;
    --color-theme-weak-3: #f0eef7;
  }

  .theme-client {
    --color-app: linear-gradient(117.05deg, #efedf5 -0.33%, #b5cbe9 100%);
    --color-theme-main: #0f499f;
    --color-theme-strong: #092c5f;
    --color-theme-weak: #6f92c5;
    --color-theme-weak-2: #cfdbec;
    --color-theme-weak-3: #f0f5fb;
  }

  .theme-admin {
    --color-app: linear-gradient(117.05deg, #f0f5fb -0.33%, #afdbd3 100%);
    --color-theme-main: #1e8c93;
    --color-theme-strong: #125458;
    --color-theme-weak: #78babe;
    --color-theme-weak-2: #d2e8e9;
    --color-theme-weak-3: #e9f4f4;
  }
}
