.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #29272F;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #555;
  border-radius: 6px;
  border: 3px solid #29272F;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #777;
}
